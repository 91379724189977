import { PageProps } from 'gatsby'
import React, { useEffect, useContext, useState } from 'react'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { LayoutContext } from 'src/features/layout'
import BugReportIcon from '@material-ui/icons/BugReport'
import SEO from 'src/common/seo'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import MibBackground from 'src/features/mls-is-back/mib-background'
import StandingsTable from 'src/features/standings/standings-table'
import { SortState, Standings } from 'src/common/types'
import { spacing, rounded, standardMargins, Card } from 'src/common/styles'
import Paper from '@material-ui/core/Paper'

const Mib = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle()
  const { setLocation } = useContext(LayoutContext)
  const [sorting, setSorting] = useState<SortState>({
    value: 'pts',
    isDesc: true,
  })
  const result = useStaticQuery<any>(query)
  const standingsData = result.allStandings.nodes[0] as Standings

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groups = ['a', 'b', 'c', 'd', 'e', 'f', 'wildcard']

  return (
    <>
      <SEO title="Mls is Back" />
      <MibBackground />
      <Container {...props}>
        <TitleWrapper>
          <GroupTitleCard variant="outlined">
            <GroupTitle>
              <BugReportIcon style={{ fontSize: 'inherit' }} />
              &nbsp;Group Stage
            </GroupTitle>
          </GroupTitleCard>
        </TitleWrapper>

        {groups.map((group) => {
          const data = standingsData[group]
          return (
            <StandingsTable
              division={group === 'wildcard' ? group : `Group\xa0${group}`}
              standingsData={data}
              sortCategory={sorting}
              setSortCategory={setSorting}
              rowCss={groupRow(data.length === 4 ? 4 : 8)}
              key={group}
            />
          )
        })}
      </Container>
    </>
  )
}

export default Mib

const Container = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: ${spacing(1)};
  ${(p) => p.theme.breakpoints.up('md')} {
    padding-bottom: ${spacing(2)};
  }
`
const TitleWrapper = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  ${standardMargins}
  & {
    margin-bottom: 0;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1.8rem;
  }
`
const GroupTitleCard = styled(Paper)`
  background-color: ${(p) => p.theme.palette.background.paper}bb;
  backdrop-filter: blur(12px);
  text-align: center;
  padding: ${spacing(1)};
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${spacing(2)};
  }
`
const GroupTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: inherit;
  margin: 0;
`
const groupRow = (n: number) => css`
  tr:nth-child(${n / 2}) {
    border-bottom: 2px solid #fff;
  }
  tr:nth-child(n + 1):nth-child(-n + ${n / 2}) {
    background-color: ${(p) => p.theme.palette.primary.main}2b;
  }
  tr:nth-child(n + ${n / 2 + 1}) {
    background-color: ${(p) => p.theme.palette.background.default}55;
    opacity: 0.85;
  }
`
const query = graphql`
  query MibStandingsData {
    allStandings {
      nodes {
        east {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        west {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        a {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        b {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        c {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        d {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        e {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        f {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        wildcard {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
      }
    }
  }
`
