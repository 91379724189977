import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GeneralBackground from '../common/general-background'

const MibBackground = () => {
  const { allFile } = useStaticQuery<any>(query)

  const fluidData = allFile.nodes[0].childImageSharp?.fluid

  return <GeneralBackground fluidData={fluidData} />
}

export default MibBackground

const query = graphql`
  query MlsIsBackBg {
    allFile(
      filter: {
        relativeDirectory: { eq: "mls-is-back-bgs" }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 30, traceSVG: { color: "#80000a" }) {
            originalName
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  }
`
